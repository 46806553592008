import { SEOMeta } from "@/components/SEOMeta";

const ErrorPage = (): JSX.Element => {
  return (
    <div className="bg-white align-middle text-center py-48">
      <SEOMeta title="Error" />
      <h1>404</h1>
      <h2>This page could not be found.</h2>
    </div>
  );
};

export default ErrorPage;
